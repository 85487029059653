<template>
    <div>

        <Heading :heading="block.heading" />

        <div class="table-responsive">

            <table class="table table-striped table-sm">
                <thead v-if="block.data.header.cols">
                    <tr>
                        <td v-for="col_index in block.data.header.cols.length" :key="col_index" :style="{width: block.data.header.cols[col_index-1].width}">
                            {{block.data.header.cols[col_index-1].text}}
                        </td>
                    </tr>
                </thead>
                <tbody v-if="block.data.rows">
                    <tr v-for="row_index in block.data.rows.length" :key="row_index">
                        <td v-for="col_index in block.data.rows[row_index-1].cols.data.length" :key="col_index">
                            <TableTd :col="block.data.rows[row_index-1].cols.data[col_index-1]" />
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="block.data.footer.cols">
                    <tr>
                        <td v-for="col_index in block.data.footer.cols.length" :key="col_index">
                            {{block.data.footer.cols[col_index-1].text}}
                        </td>
                    </tr>
                </tfoot>
            </table>

        </div>

    </div>
</template>

<script>
    import TableTd from '@/components/TableTd.vue'

    export default {
        name: "Table",
        props: {
            block: Object
        },
        data() {
            return {}
        },
        components: {
            TableTd
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
    }
</style>
