<template>
    <div>
        <h2>{{heading.title}} <small>{{heading.sub_heading}}</small></h2>
        <p><small>{{heading.sub_heading2}}</small></p>
    </div>
</template>

<script>
    export default {
        name: "Heading",
        props: {
            heading: Object,
        },
        data() {
            return {}
        },
    }
</script>

<style>

</style>
