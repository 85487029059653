<template>
    <div class="row">
        <template v-for="block in blocks">
            <div :class="`${block.class}`" :key="block._uid">
                <div class="card item-card card-block" style="padding:20px;margin:10px 0px;">

                    <component v-if="block.component !== 'Multi'" :is="block.component" :block="block"></component>

                    <div v-if="block.component === 'Multi'" :key="block._uid">

                        <Heading :heading="block.heading" />

                        <div class="row">
                            <template v-for="block2 in block.blocks">
                                <div :class="`${block2.class}`" :key="block2._uid">
                                    <div >
                                        <component :is="block2.component" :block="block2"></component>

                                    </div>
                                </div>
                            </template>
                        </div>

                    </div>

                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Chart from '@/components/Chart.vue'
    import Table from '@/components/Table.vue'
    import Raw from '@/components/Raw.vue'
    import Heading from "./Heading";
    //import BlockContainer from '@/components/BlockContainer.vue'

    export default {
        props: {
            msg: String,
            blocks: Array
        },
        data() {
            return {
                msg2: "",
                loaded: false,
                loading: false,
            }
        },
        name: 'Blocks',
        components: {
            Heading,
            Chart,
            Table,
            Raw,
            BlockContrainer: () => import('./BlockContainer.vue')
        },
        methods: {

        },
        mounted() {

        }
    }
</script>

<style scoped>
</style>
