<template>
    <div>
        {{message}}
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Authenticate',
        data() {
            return {
                message: "Authenticating..."
            }
        },
        mounted() {
            this.checkToken();
        },
        methods: {
            checkToken() {
                console.log(this.$route.query);
                if(this.$route.query.token===undefined) {
                    return;
                }

                let url = "http://localhost:5000/authenticate/" + this.$route.query.token;
                console.log(url);
                axios.get(url)
                    .then(res => {
                        console.log(res);
                        this.message = res.data;
                        if(res.data.status) {
                            this.$cookies.set("rittyReportingSession", this.$route.query.token);
                            this.$router.push({ path: '/report/dashboard'});
                        }

                    })
                    .catch(err => {
                        console.error(err);

                        this.message = err.toString();
                    });
            }
        },
    }
</script>
