<template>
    <nav class="col-md-2 d-none d-md-block bg-light sidebar">
        <div class="sidebar-sticky">
            <ul class="nav flex-column">
                <li
                        class="nav-item"
                        v-for="item in items"
                        :key="item._uid"
                >
                    <router-link :to="item.to" class="nav-link active">
                        <span v-html="item.text"></span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Nav",
        data() {
            return {
                items: [
                ]
            }
        },
        methods: {
            init() {
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + this.$cookies.get("rittyReportingSession")
                    }
                };

                axios
                    .get("http://localhost:5000/nav/", config)
                    .then(response => {
                        this.items = response.data.items;
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                        //this.loading = false;
                        //this.loaded = true;
                    })
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style>

</style>
