<template>
    <div>

        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">

            <h1 class="h2">{{ heading }}</h1>

            <div class="btn-toolbar mb-2 mb-md-0">
                <div class="btn-group mr-2">
                    <button class="btn btn-sm btn-outline-secondary">Share</button>
                    <button class="btn btn-sm btn-outline-secondary">Export</button>
                </div>
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle">
                    <span data-feather="calendar"></span>
                    This week
                </button>

                <button @click="init" class="btn btn-primary">Play Again</button>
            </div>
        </div>

        <!--{{loading}}-->

        <Blocks :blocks="blocks" />

        <div class="overlay" v-if="loading"><div id="loader" class="lds-dual-ring"></div></div>

    </div>
</template>

<script>
    import axios from 'axios'
    import Blocks from '@/components/Blocks.vue'

    export default {
        props: {
            url: String,
            manual_blocks: Array
        },
        data() {
            return {
                heading: "",
                loaded: false,
                loading: false,
                blocks: []
            }
        },
        name: 'BlockContainer',
        components: {
            Blocks
        },
        methods: {
            init() {
                console.log(this.manual_blocks);
                if(this.manual_blocks) {
                    this.blocks = this.manual_blocks;
                    return;
                }

                if(this.url==='') return;

                this.loading = true;
                this.loaded = false;

                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + this.$cookies.get("rittyReportingSession")
                    }
                };

                axios
                    .get(this.url, config)
                    .then(response => {
                        this.heading = response.data.heading;
                        this.blocks = response.data.blocks;
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.loading = false;
                        this.loaded = true;
                    })
            }
        },
        mounted() {
            console.log(this.$router.currentRoute);
            this.init();
        },
        watch: {
            url: function (val, oldVal) {
                console.log('new: %s, old: %s', val, oldVal)
                this.init();
            },
        }
    }
</script>

<style scoped>
    /*Add an overlay to the entire page blocking any further presses to buttons or other elements.*/
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,.1);
        z-index: 999;
        opacity: 1;
        transition: all 0.5s;
    }

    /*Spinner Styles*/
    .lds-dual-ring {
        margin:20% auto;
        width: 80px;
        height: 80px;
    }
    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 5% auto;
        border-radius: 50%;
        border: 6px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
