<template>
    <div class="chart">

        <Heading :heading="block.heading" />

        <BarChart v-if="block.data.type === 'Bar'" :chartData="block.data.chartData" :chartOptions="block.data.chartOptions" :styles="myStyles"/>

        <LineChart v-if="block.data.type === 'Line'" :chartData="block.data.chartData" :chartOptions="block.data.chartOptions" :styles="myStyles"/>

        <PieChart v-if="block.data.type === 'Pie'" :chartData="block.data.chartData" :chartOptions="block.data.chartOptions" :styles="myStyles"/>

    </div>
</template>

<script>
    import BarChart from '@/components/charts/BarChart.vue'
    import LineChart from '@/components/charts/LineChart.vue'
    import PieChart from '@/components/charts/PieChart.vue'
    import Heading from '@/components/Heading.vue'

    export default {
        name: "Chart",
        props: {
            block: Object,
        },
        data() {
            return {}
        },
        computed: {
            myStyles() {
                return {
                    height: `${this.block.data.chartOptions.height}px`,
                    position: 'relative',
                }
            }
        },
        components: {
            BarChart,
            LineChart,
            PieChart,
            Heading
        }
    }
</script>

<style scoped>

</style>
