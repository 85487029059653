<template>
    <div class="home">

        <nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
            <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">{{company_name}}</a>
            <!--<input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search">-->
            <!--<ul class="navbar-nav px-3">
                <li class="nav-item text-nowrap">
                    <a class="nav-link" href="#">Sign out</a>
                </li>
            </ul>-->
        </nav>

        <div class="container-fluid">
            <div class="row">

                <Nav />

                <main role="main" class="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4" style="background:#EEEEEE;">
                    <div class="container">

                        <!--{{url}}-->

                        <BlockContainer :url="url"/>

                    </div>
                </main>
            </div>
        </div>

    </div>
</template>

<script>
    // @ is an alias to /src
    import BlockContainer from '@/components/BlockContainer.vue'
    import Nav from '@/components/Nav.vue'

    export default {
        name: 'Report',
        data() {
            return {
                url: "",
                company_name: "Reporting"
            }
        },
        components: {
            BlockContainer,
            Nav
        },
        mounted() {
            this.changeReport(this.$router.currentRoute.params.report_type);
        },
        methods: {
            changeReport(report_type) {
                this.url = "http://localhost:5000/reports/" + report_type + "?test=231313";
            }
        },
        watch: {
            $route(to, from) {
                // react to route changes...
                console.log(to);
                console.log(from);
                this.changeReport(to.params.report_type)
                console.log(this.url);
                this.init();
            }
        }
    }
</script>
