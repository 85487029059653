<template>
    <div class="raw">

        <Heading :heading="block.heading" />

        <div v-if="block.data.blocks.length">
            <template v-for="sub_block in block.data.blocks">
                Block

                {{sub_block}}}
                {{sub_block.component}}

                <component :is="sub_block.component" :block="sub_block" :key="sub_block._uid">
                    here
                </component>

            </template>
        </div>

    </div>
</template>

<script>

    export default {
        name: "Raw",
        props: {
            block: Object,
        },
        data() {
            return {}
        },
        components: {

        }
    }
</script>

<style scoped>

</style>
