<template>
    <span>
        {{col.text}}

        <template v-for="block in col.blocks">
            <component :is="block.component" :block="block" :key="block._uid"></component>
        </template>
    </span>
</template>

<script>
    import Chart from '@/components/Chart.vue'

    export default {
        name: "TableTd",
        props: {
            col: Object,
        },
        data() {
            return {}
        },
        components: {
            Chart
        }
    }
</script>
